import { render, staticRenderFns } from "./group.vue?vue&type=template&id=72c840ad&scoped=true&"
import script from "./group.vue?vue&type=script&lang=js&"
export * from "./group.vue?vue&type=script&lang=js&"
import style0 from "./group.vue?vue&type=style&index=0&id=72c840ad&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "72c840ad",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ElementsHero: require('/home/ticketshoplv/public_html/ic3peak.tour.tickets/components/elements/Hero.vue').default})
